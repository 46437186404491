export const leboncoinMarkdown = `
To deepen my understanding of react, I chose to build a clone of the leboncoin website (A French Craigslist equivalent). 

I also developed the API and stored data on MongoDB.`;

export default {
  title: 'leboncoin',
  duration: '5 days',
  subtitle:
    'A full-stack online market place clone web application of leboncoin',
  overview: leboncoinMarkdown,
  features: [
    'Display all offers',
    'Display single offer',
    'Images carousel',
    'Search filters',
    'Pagination',
    'Cookies set for user identity and search filters',
    'Create an user account',
    'Login to your user account',
    'Publish an offer (using cloudinary to store images)',
    "Access to your user profile displaying all the offers you've published",
    'The user can delete his/ her offers',
    'Loading component implemented',
    'Mobile responsive',
  ],
  technologies: [
    'html5',
    'css',
    'javascript',
    'react',
    'react router dom',
    'enzyme',
    'axios',
    'cloudinary',
    'nodejs',
    'express',
    'express router',
    'mongodb',
    'mongoose',
    'heroku',
    'mLab',
    'git',
  ],
  pictures: [
    'leboncoin/leboncoin_browser.png',
    'leboncoin/leboncoin_offres.jpg',
    'leboncoin/leboncoin_publish.jpg',
    'leboncoin/leboncoin_responsive.png',
  ],
  url: 'https://leboncoin-alex-client.herokuapp.com/',
  code: 'https://github.com/alexdisdier/leboncoin-client',
};
