import styled from 'styled-components';

export const StyledSocialMedia = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-bottom: 20px;

  a {
    font-weight: 700;
    text-decoration: none;
    color: var(--primary-color);
  }

  li {
    margin: 0 8px;
    transition: 250ms linear;
  }

  a:hover {
    color: var(--primary-color);
    opacity: 0.7;
  }

  svg {
    width: 20px;
    fill: var(--primary-color);
    transition: 250ms linear;
  }

  a:hover svg {
    fill: var(--primary-color);
    opacity: 0.7;
  }
`;
