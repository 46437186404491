import styled from 'styled-components';

const StyledFooter = styled.footer`
  margin-top: 24px;
  padding: 32px;
  color: #8892b0;
  text-align: center;
  opacity: 0.7;

  span {
    line-height: 20px;
    color: var(--primary-color);
  }
`;

export default StyledFooter;
