export default {
  title: 'tic tac toe',
  duration: '1 day',
  subtitle: 'A web application',
  overview: 'A tic tac toe game Open Source based on on the one from google.',
  features: ['Two players', 'Restart the game', 'Mobile responsive'],
  technologies: ['html5', 'css', 'javascript', 'react', 'git'],
  pictures: [
    'tictactoe/tictactoe_browser.png',
    'tictactoe/tictactoe_draw.jpg',
    'tictactoe/tictactoe_game.jpg',
  ],
  url: 'https://alexdisdier.github.io/tic-tac-toe/',
  code: 'https://github.com/alexdisdier/tic-tac-toe',
};
