import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import projects from '../../assets/data/projects';

import { Header } from '../../components';
import Helmet from '../../components/Helmet';

import ActionButtons from './ActionButtons';
import Carousel from './Carousel';
import Features from './Features';
import PortfolioFooter from './PortfolioFooter';
import TechnicalSheet from './TechnicalSheet';

import './Portfolio.css';

const Portfolio = ({
  match: {
    params: { projectTitle },
  },
}) => {
  const currentProjectIndex = projects.findIndex(
    (pj) => pj.title === projectTitle
  );

  const {
    title,
    subtitle,
    pictures,
    url,
    code,
    overview,
    features,
    technologies,
  } = projects.filter((pj) => pj.title === projectTitle)[0];

  const nextProject = useMemo(() => {
    if (currentProjectIndex === projects.length - 1) return '';
    return projects[currentProjectIndex + 1].title;
  }, [currentProjectIndex]);

  const previousProject = useMemo(() => {
    if (currentProjectIndex === 0) return '';
    return projects[currentProjectIndex - 1].title;
  }, [currentProjectIndex]);

  return (
    <div className="page">
      <Helmet
        lang="en"
        titleEn={title}
        contentEn={subtitle}
        canonicalUrl={`https://alexdisdier.fr/portfolio/${title}`}
      />
      <div className="container">
        <div className="page-header">
          <div className="page-header-title">
            <h2>{title}</h2>
          </div>

          <Header />
        </div>
        <h3>{subtitle}</h3>

        <Carousel pictures={pictures} title={title} />

        <ActionButtons url={url} code={code} />

        <div className="project-content">
          <h3>In a nutshell</h3>
          <div id="project-markdown">
            <ReactMarkdown source={overview} />
          </div>

          <Features features={features} />

          <TechnicalSheet technologies={technologies} />
        </div>
        <PortfolioFooter
          previousProject={previousProject}
          nextProject={nextProject}
        />
      </div>
    </div>
  );
};

export default Portfolio;
