export const currencyMarkdown = `
I chose to work on this Open Source project during the challenge [#100daysOfCode](https://www.100daysofcode.com/) to improve my React understanding and API manipulation (data.fixer).`;

export default {
  title: 'currency converter',
  duration: '1 day',
  subtitle: 'A web application',
  overview: currencyMarkdown,
  features: [
    'Simultaneously converts any currency',
    'Error message in case the user does not enter a number',
    'Mobile responsive',
  ],
  technologies: ['html5', 'css', 'javascript', 'react', 'axios', 'git', 'pwa'],
  pictures: ['currency_browser.png'],
  url: 'https://alexdisdier.github.io/currency-converter/',
  code: 'https://github.com/alexdisdier/currency-converter',
};
