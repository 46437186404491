import styled from 'styled-components';

export const StyledInputWrapper = styled.div`
  display: flex;
  margin: 32px 0;
`;

export const StyledInput = styled.input`
  padding-left: 12px;
  padding-bottom: 16px;
  width: 100%;
  font-size: 16px;
  color: #fff;
  border: none;
  border-bottom: 0.5px solid gray;
  background: transparent;

  &:focus {
    outline: none;
  }
`;

export const StyledSuccessSnackbar = styled.div`
  background: var(--primary-color);
  position: absolute;
  padding: 20px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  color: var(--main-background-color);
  z-index: 99999;
`;

export const StyledSubmitBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;

  button {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 16px;
    border: 1px solid var(--primary-color);
    padding: 8px 16px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: 250ms linear;
    text-decoration: none;

    &:hover {
      background-color: var(--primary-color);
      color: var(--secondary-color);
    }

    &:focus {
      outline: none;
    }
  }
`;

export default {
  StyledSubmitBtn,
  StyledInputWrapper,
  StyledInput,
  StyledSuccessSnackbar,
};
