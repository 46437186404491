import ReactGA from 'react-ga';

const useAnalyticsEventTracker = (category = 'no category') => {
  const eventTracker = (action = 'no action', label = 'no label') => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;

/** Some examples
 * Header Clicks – CTA Button
 * - Event Category = Header
 * - Event Action = Clicked CTA Button
 * - Event Label = example.com/pricing_plans
 *
 * Sidebar Clicks – Download Links
 * - Event Category = Left Sidebar
 * - Event Action = Clicked Download Guide
 * - Event Label = https://example.com/download-free-google-analytics-guide
 */
