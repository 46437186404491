import React from 'react';
import PropTypes from 'prop-types';
import Animate from 'react-smooth';

import Helmet from '../../components/Helmet';

import StyledPageHeader from '../../components/Header/Header.style';

import { StyledAboutRoot } from './About.style';

export const About = ({
  about: { firstname, lastname, description, profile },
}) => {
  return (
    <div className="page">
      <Helmet
        lang="en"
        titleEn="About"
        contentEn={description}
        canonicalUrl="https://alexdisdier.fr/about"
      />
      <div className="container">
        <StyledPageHeader>
          <Animate to="1" from="0" attributeName="opacity">
            <div className="page-header-title">
              <h2>about</h2>
            </div>
          </Animate>
        </StyledPageHeader>
        <StyledAboutRoot data-testid="about-profile">
          {profile !== '' && (
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/${profile}`}
              alt={firstname}
            />
          )}

          <h3>
            {firstname} {lastname}
          </h3>
          <Animate to="1" from="0" attributeName="opacity">
            <p>
              After a decade in film and theatre, programming is my new craft. I
              code with empathy because I believe improving developer experience
              improves the end user experience.
              <br />
              <br />I aim to bridge users, stakeholders and software developers
              while taking architecture decisions and staying close to
              programming.
              <br />
              <br />I am currently employed at Trainline (Europe&#39;s leading
              train and coach app) as a Software Engineer. Through either our
              web interface or mobile apps, customers can purchase tickets for a
              variety of European rail operators. We also sell loyalty cards or
              vouchers and deliver e-ticket support. In 2016, Captain Train was
              acquired by Trainline.
              <br />
              For more details, visit{' '}
              <a
                href="https://www.thetrainline.com/"
                target="_blank"
                rel="noreferrer"
              >
                www.thetrainline.com
              </a>
              <br />
              <br />I try to stay tuned to the latest technologies at our
              disposal and I create side projects to test them out or refactor
              some old projects.
              <br />
              <br />I also enjoy reading some industry classics such as the
              pragmatic programmer, clean code or coders at work and taking
              continuous training. Learning, is a crucial essence of our
              profession as software developers.
              <br />
              <br />
              Some side activities which I manage to squeeze in are running,
              dancing, listening to some of my favorite podcasts like
              stackoverflow, software engineering daily or Syntax.
              <br />
              <br />
              But most of all, I make time for my family with whom I enjoy
              casual walks, dance off, traveling, and trying out new food.
            </p>
          </Animate>
        </StyledAboutRoot>
      </div>
    </div>
  );
};

About.propTypes = {
  about: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    description: PropTypes.string,
    profile: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
};

export default About;
