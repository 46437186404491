export const reactHeliumMarkdown = `
This is an atomic design component library originally built to gain time on my personal projects. 

It is in working progress and I try to add new components during my free time`;

export default {
  title: 'react-helium',
  duration: '1 week',
  subtitle: 'A UI component library',
  overview: reactHeliumMarkdown,
  features: [
    'Display all UI components',
    'Test the components controls',
    'Quick documentation on how to import the component',
  ],
  technologies: [
    'react',
    'javascript',
    'typescript',
    'enzyme',
    'storybook',
    'git',
    'npm',
    'semantic release',
  ],
  pictures: ['react-helium.png'],
  url: 'https://alexdisdier.github.io/react-helium/?path=/story/welcome--to-helium',
  code: 'https://github.com/alexdisdier/react-helium',
};
