import React, { memo, useState, useMemo, useCallback } from 'react';

import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';

import { Header, Button, Card } from '../../components';
import Helmet from '../../components/Helmet';

import { StyledHomeRoot, StyledGalleryContainer } from './Home.style';

const SHOW_ALL = 'show all';

export const parseFilters = (filter, projects) => {
  const newFilters = [];

  projects.map((project) =>
    project.technologies.map(
      (technology) => technology === filter && newFilters.push(project.title)
    )
  );

  return newFilters;
};

export const Home = (props) => {
  const [showAll, setShowAll] = useState(true);
  const [filterTag, setFilterTag] = useState(SHOW_ALL);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const gaEventTracker = useAnalyticsEventTracker('Home');

  const { tags, metaEn, projects } = props;

  const filterSelect = (filter) => {
    const newFilters = parseFilters(filter, projects);

    setShowAll(false);
    setFilteredProjects(newFilters);
    setFilterTag(filter);
    gaEventTracker('Clicked on filters', filter);
  };

  const renderCards = useMemo(() => {
    const render = [];

    for (let i = 0; i < projects.length; i += 1) {
      if (!showAll) {
        if (filteredProjects.includes(projects[i].title)) {
          render.push(
            <Card
              key={i}
              title={projects[i].title}
              pictures={projects[i].pictures[0]}
              {...props}
            />
          );
        }
      } else {
        render.push(
          <Card
            key={i}
            title={projects[i].title}
            pictures={projects[i].pictures[0]}
            {...props}
          />
        );
      }
    }
    return render;
  }, [filteredProjects, projects, props, showAll]);

  const resetFilters = useCallback(() => {
    if (!showAll) {
      setShowAll(true);
      setFilteredProjects([]);
      setFilterTag(SHOW_ALL);
    }
    return renderCards;
  }, [renderCards, showAll]);

  const renderFilterInfo = useMemo(() => {
    if (!showAll) {
      return (
        <p>
          {`Showing ${filteredProjects.length} projects filtered by ${filterTag}.`}
        </p>
      );
    }
    return <p>Showing all projects.</p>;
  }, [filterTag, filteredProjects.length, showAll]);

  return (
    <div className="page">
      <Helmet
        lang="en"
        titleEn="Home"
        contentEn={metaEn}
        canonicalUrl="https://alexdisdier.fr/"
      />
      <div className="container">
        <Header pageTitle="Alex Disdier" lang="en" home />
        <StyledHomeRoot>
          {tags.map((tag) => {
            return (
              <Button
                data-testid={tag.replace(' ', '-')}
                key={tag}
                name={tag}
                btnType="tag"
                filterSelect={filterSelect}
                resetFilters={resetFilters}
                filterTag={filterTag}
              />
            );
          })}
        </StyledHomeRoot>
        {renderFilterInfo}
        <StyledGalleryContainer>{renderCards}</StyledGalleryContainer>
      </div>
    </div>
  );
};

export default memo(Home);
