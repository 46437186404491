export const deliverooMarkdown = `
To deepen my understanding of react, I chose to build a Front-End clone of the deliveroo web application (solely for the Pain Quotidien restaurant). I used an API already in place.`;

export default {
  title: 'deliveroo',
  duration: '3 days',
  subtitle: 'A Front-End clone web application of deliveroo',
  overview: deliverooMarkdown,
  features: [
    'Display all meals',
    'Add a meal to the basket',
    'Remove a meal from the basket',
    'Modify the quantity of a meal in the basket',
    'Add a tip',
    'Calculate the basket according to delivery fees and tip',
    'Mobile responsive',
  ],
  technologies: [
    'html5',
    'css',
    'javascript',
    'react',
    'enzyme',
    'axios',
    'git',
  ],
  pictures: [
    'deliveroo/deliveroo_browser.png',
    'deliveroo/deliveroo_responsive.png',
  ],
  url: 'https://alexdisdier.github.io/deliveroo/',
  code: 'https://github.com/alexdisdier/deliveroo',
};
