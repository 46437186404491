import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from './Image';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import './ImageContainer.css';

export const ImageContainer = ({ height, width, src, thumb, alt, url }) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        if (!isVisible) setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    },
  });

  const aspectRatio = (height / width) * 100;

  return (
    <Link
      ref={ref}
      className="image-container"
      to={url}
      style={{
        paddingBottom: `${aspectRatio}%`,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <p id="image-layer-text">{alt}</p>
      {isVisible && <Image src={src} thumb={thumb} alt={alt} />}
    </Link>
  );
};

ImageContainer.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  thumb: PropTypes.string,
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

ImageContainer.defaultProps = {
  thumb: '',
};

export default memo(ImageContainer);
