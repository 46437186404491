export const pomodoroTimerMarkdown = `
I chose to work on this Open Source project during the challenge [#100daysOfCode](https://www.100daysofcode.com/) to enhance my understanding of React and how to implement a [Progressive Web App](https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps). The most challenging part of this project was to design all the different splash screens for the different formats of smartphone. I used Figma.`;

export default {
  title: 'pomodoro timer',
  duration: '4 days',
  subtitle: 'A Progressive Web App built with React',
  overview: pomodoroTimerMarkdown,
  features: [
    'Select your time to work',
    'Select your time to break',
    'Select the number of cycles',
    'Reset your settings',
    'Progressive Web App',
    'Mobile responsive',
  ],
  technologies: ['html5', 'scss', 'javascript', 'react', 'git', 'figma', 'pwa'],
  pictures: ['pomodoro.png'],
  url: 'https://alexdisdier.github.io/pomodoro-timer/',
  code: 'https://github.com/alexdisdier/pomodoro-timer',
};
