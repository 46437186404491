import React, { memo } from 'react';
import PropTypes from 'prop-types';

import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';

import { ReactComponent as Github } from '../../assets/img/logo/github.svg';
import { ReactComponent as Linkedin } from '../../assets/img/logo/linkedin.svg';
import { ReactComponent as Twitter } from '../../assets/img/logo/twitter.svg';

import { StyledSocialMedia } from './SocialMedia.style';

const linkProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

export const SocialMedia = ({ cv, socialMedia }) => {
  const gaEventTracker = useAnalyticsEventTracker('Footer');

  return (
    <StyledSocialMedia>
      <li>
        <a
          href={`${process.env.PUBLIC_URL}/assets/resources/${cv}`}
          title="CV"
          onClick={() =>
            gaEventTracker(
              'Clicked on CV',
              `${process.env.PUBLIC_URL}/assets/resources/${cv}`
            )
          }
          {...linkProps}
        >
          CV
        </a>
      </li>
      <li>
        <a
          href={socialMedia.github}
          aria-label="Github"
          onClick={() =>
            gaEventTracker('Clicked on GitHub', socialMedia.github)
          }
          {...linkProps}
        >
          <Github />
        </a>
      </li>
      <li>
        <a
          href={socialMedia.linkedIn}
          aria-label="LinkedIn"
          onClick={() =>
            gaEventTracker('Clicked on LinkedIn', socialMedia.linkedIn)
          }
          {...linkProps}
        >
          <Linkedin />
        </a>
      </li>
      <li>
        <a
          href={socialMedia.twitter}
          aria-label="Twitter"
          onClick={() =>
            gaEventTracker('Clicked on Twitter', socialMedia.twitter)
          }
          {...linkProps}
        >
          <Twitter />
        </a>
      </li>
    </StyledSocialMedia>
  );
};

SocialMedia.propTypes = {
  cv: PropTypes.string.isRequired,
  socialMedia: PropTypes.shape({
    github: PropTypes.string,
    linkedIn: PropTypes.string,
    twitter: PropTypes.string,
  }).isRequired,
};

export default memo(SocialMedia);
