import styled from 'styled-components';

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  h2 {
    margin: 40px 0;
    font-size: 32px;
    line-height: 42px;
    color: var(--primary-color);
  }

  a {
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
    transition: 250ms linear;
  }

  a:hover {
    opacity: 0.7;
  }

  @media only screen and (min-width: 768px) {
    h2 {
      margin: 80px 0;
      /* line-height: 70px; */
      line-height: calc(50px + 40%);
      font-size: 50px;
    }
  }
`;

export default StyledPageHeader;
