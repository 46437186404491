import React from 'react';
import { Link } from 'react-router-dom';

import './PortfolioFooter.css';

const PortfolioFooter = ({ previousProject, nextProject }) => {
  return (
    <div className="btn-navigate-container">
      <Link
        to={previousProject !== '' ? `/portfolio/${previousProject}` : '/'}
        className="tag-btn"
      >
        {previousProject !== '' ? `<- ${previousProject}` : 'portfolio'}
      </Link>
      <Link
        to={nextProject !== '' ? `/portfolio/${nextProject}` : '/'}
        className="tag-btn"
      >
        {nextProject !== '' ? `${nextProject} ->` : 'portfolio'}
      </Link>
    </div>
  );
};

export default PortfolioFooter;
