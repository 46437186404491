import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import StyledInputFeedback from './InputFeedback.style';

export const InputFeedback = ({ error }) => {
  const errorMessage = useMemo(() => {
    switch (error) {
      case 'name':
        return 'I like to call people by their name, What should I call you?';

      case 'email':
        return "If you want me to get back to you, I'll need that @";

      case 'message':
        return 'How may I help you?';

      default:
        return null;
    }
  }, [error]);

  return <StyledInputFeedback>{errorMessage}</StyledInputFeedback>;
};

InputFeedback.propTypes = {
  error: PropTypes.string.isRequired,
};

export default memo(InputFeedback);
