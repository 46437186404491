import React, { useEffect, useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';

import { ROUTE_ABOUT, ROUTE_CONTACT, ROUTE_HOME } from '../../constant/routes';

import { ReactComponent as MenuBtn } from '../../assets/img/svg/menu-button.svg';
import { ReactComponent as CloseBtn } from '../../assets/img/svg/close-button.svg';

import {
  StyledMenu,
  StyledMenuBtn,
  StyledMenuLinks,
  StyledMenuLink,
  StyledWrapper,
} from './Menu.style';

import './Menu.css';

const LOCALSTORAGE_THEME_ALEXDISDIER = 'theme-alexdisdier';
const THEME = {
  light: 'light',
  dark: 'dark',
};

export const Menu = ({ isToggle, toggleMenu }) => {
  const gaEventTracker = useAnalyticsEventTracker('Header');
  const [theme, setTheme] = useState(THEME.light);
  const nextTheme = theme === THEME.light ? THEME.dark : THEME.light;

  useEffect(() => {
    if (localStorage.getItem(LOCALSTORAGE_THEME_ALEXDISDIER)) {
      setTheme(localStorage.getItem(LOCALSTORAGE_THEME_ALEXDISDIER));
    }
    document.body.dataset.theme = theme;
  }, [theme]);

  const handleToggleTheme = () => {
    setTheme(nextTheme);
    localStorage.setItem(LOCALSTORAGE_THEME_ALEXDISDIER, nextTheme);
    gaEventTracker('Switched theme color', nextTheme);
  };

  const toggleThemeBtnContent = `Activate ${nextTheme} mode`;

  const renderButtons = useMemo(() => {
    if (!isToggle) return null;

    return (
      <StyledMenu>
        <NavLink to={ROUTE_HOME} className="menu-item" onClick={toggleMenu}>
          home
        </NavLink>
        <NavLink
          data-testid="to-about"
          to={ROUTE_ABOUT}
          className="menu-item"
          onClick={toggleMenu}
        >
          about
        </NavLink>
        <NavLink to={ROUTE_CONTACT} className="menu-item" onClick={toggleMenu}>
          contact
        </NavLink>
      </StyledMenu>
    );
  }, [isToggle, toggleMenu]);

  return (
    <>
      <StyledWrapper
        className="container"
        style={{
          paddingTop: 24,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 4,
          position: isToggle ? 'sticky' : 'initial',
          top: isToggle ? '0' : 'initial',
        }}
      >
        <label
          aria-label={toggleThemeBtnContent}
          title={toggleThemeBtnContent}
          htmlFor="slider"
          id="switch"
          className="switch"
        >
          <input
            type="checkbox"
            onChange={handleToggleTheme}
            id="slider"
            checked={theme === THEME.dark}
          />
          <span className="switch-slider round" />
        </label>

        <StyledMenuBtn
          data-testid="toggle-menu"
          aria-label="toggle the menu"
          onClick={toggleMenu}
        >
          {!isToggle ? <MenuBtn /> : <CloseBtn />}
        </StyledMenuBtn>
        <StyledMenuLinks>
          <StyledMenuLink exact to={ROUTE_HOME}>
            Home
          </StyledMenuLink>

          <StyledMenuLink to={ROUTE_ABOUT}>About</StyledMenuLink>

          <StyledMenuLink to={ROUTE_CONTACT}>Contact</StyledMenuLink>
        </StyledMenuLinks>
      </StyledWrapper>

      {renderButtons}
    </>
  );
};

Menu.propTypes = {
  isToggle: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default memo(Menu);
