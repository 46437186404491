import {
  airbnb,
  calculator,
  currencyConverter,
  deliveroo,
  kidiwi,
  leboncoin,
  pacificAce,
  pomodoro,
  reactHelium,
  shortUrl,
  ticTacToe,
  todo,
} from './individualProject';

export default [
  kidiwi,
  pacificAce,
  airbnb,
  leboncoin,
  deliveroo,
  todo,
  pomodoro,
  reactHelium,
  shortUrl,
  calculator,
  currencyConverter,
  ticTacToe,
];
