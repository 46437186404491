import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

import {
  ROUTE_HOME,
  ROUTE_ABOUT,
  ROUTE_CONTACT,
  ROUTE_PORTFOLIO_PROJECT_TITLE,
} from './constant/routes';

import { Footer, Menu } from './components';

import { About, Contact, Home } from './containers';
import Portfolio from './containers/Portfolio/Portfolio';
import ScrollToTop from './components/ScrollToTop';

import user from './assets/data/user.json';
import projects from './assets/data/projects';

import './assets/css/reset.css';
import './App.css';

const TRACKING_ID = 'UA-30690287-2';
ReactGA.initialize(TRACKING_ID);

const TAGS = [
  'show all',
  'react',
  'react native',
  'typescript',
  'javascript',
  'nodejs',
];

const LANG_EN = 'en';

export const App = () => {
  const [lang, setLang] = useState(LANG_EN);
  const [isToggle, setIsToggle] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize());

    // cleanup event
    return () => window.removeEventListener('resize', handleResize());
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const toggleMenu = useCallback(() => setIsToggle(!isToggle), [isToggle]);

  const toggleLang = (value) => setLang(value);

  const renderMain = useMemo(
    () => (
      <>
        <Menu
          isToggle={isToggle}
          toggleMenu={toggleMenu}
          toggleLang={toggleLang}
        />

        <Switch>
          <Route
            exact
            path={ROUTE_HOME}
            render={(props) => {
              return (
                <Home
                  {...props}
                  metaEn={user.meta}
                  projects={projects}
                  tags={TAGS}
                  lang={lang}
                  toggleLang={toggleLang}
                />
              );
            }}
          />
          <Route
            exact
            path={ROUTE_ABOUT}
            render={(props) => {
              return <About {...props} about={user} />;
            }}
          />
          <Route
            path={ROUTE_PORTFOLIO_PROJECT_TITLE}
            render={(props) => (
              <Portfolio {...props} lang={lang} toggleLang={toggleLang} />
            )}
          />
          <Route
            exact
            path={ROUTE_CONTACT}
            render={(props) => {
              return (
                <Contact
                  {...props}
                  about={user}
                  lang={lang}
                  toggleLang={toggleLang}
                />
              );
            }}
          />
        </Switch>
        <Footer about={user} lang={lang} windowWidth={windowWidth} />
      </>
    ),
    [isToggle, lang, toggleMenu, windowWidth]
  );

  return (
    <Router>
      <ScrollToTop>
        <div className="portfolio">{renderMain}</div>
      </ScrollToTop>
    </Router>
  );
};

export default App;
