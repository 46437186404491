export const calculatorMarkdown = `
I chose to work on this Open Source project during the challenge [#100daysOfCode](https://www.100daysofcode.com/) to enhance my understanding of React and how to implement a [Progressive Web App](https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps). The most challenging part of this project was to design all the different splash screens for the different formats of smartphone. I used Figma.`;

export default {
  title: 'calculator',
  duration: '3 days',
  subtitle: 'A Progressive Web App built with React',
  overview: calculatorMarkdown,
  features: ['Standard operands', 'Percentage', 'pwa'],
  technologies: ['html5', 'css', 'javascript', 'react', 'git', 'pwa'],
  pictures: ['calculator.png'],
  url: ' https://alexdisdier.github.io/javascript-calculator/',
  code: 'https://github.com/alexdisdier/javascript-calculator',
};
