import React from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './Carousel.css';

const Carousel = ({ pictures, title }) => {
  return (
    <ResponsiveCarousel
      key={title}
      showIndicators={false}
      showThumbs={pictures.length > 1}
      showStatus={false}
    >
      {pictures &&
        pictures.map((img) => {
          return (
            <div key={img}>
              <img
                src={
                  img !== ''
                    ? `${process.env.PUBLIC_URL}/assets/img/${img}`
                    : ''
                }
                alt={title}
              />
            </div>
          );
        })}
    </ResponsiveCarousel>
  );
};

export default Carousel;
