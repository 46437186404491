import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { InputFeedback } from './InputFeedback';

import {
  StyledSubmitBtn,
  StyledInputWrapper,
  StyledInput,
  StyledSuccessSnackbar,
} from './Form.style';

const MAILING_URL = 'https://portfolio-ad-api.herokuapp.com/mail';

const formSubmittedMsg = {
  en: 'Message being sent. You will be redirected to the home page',
  fr: "Le message est en train d'être envoyé, vous serez redirigé vers la page d'accueil",
};

export const Form = ({ recipient, history }) => {
  const renderSubmit = (loading) => {
    if (loading) return <p>{formSubmittedMsg.en}</p>;

    return (
      <StyledSubmitBtn>
        <button data-testid="submit-form" type="submit">
          Submit
        </button>
      </StyledSubmitBtn>
    );
  };

  return (
    <>
      <Formik
        initialValues={{ name: '', email: '', message: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }

          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }

          if (!values.message) {
            errors.message = 'Required';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const data = {
              from: values.email,
              recipient,
              message: {
                subject: `Enquiry from ${values.name}`,
                text: values.message,
                html: <p>{values.message}</p>,
              },
              attachment: '',
            };

            await axios.post(MAILING_URL, data, setSubmitting(true));

            setSubmitting(false);
            history.push('/');
          } catch (error) {
            setSubmitting(false);
            console.error('an error occured');
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <StyledInputWrapper>
              <StyledInput
                data-testid="input-name"
                type="text"
                name="name"
                placeholder="Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
            </StyledInputWrapper>
            {errors.name && touched.name && (
              <InputFeedback data-testid="error-name" error="name" />
            )}

            <StyledInputWrapper>
              <StyledInput
                data-testid="input-email"
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            </StyledInputWrapper>

            {errors.email && touched.email && (
              <InputFeedback data-testid="error-email" error="email" />
            )}

            <StyledInputWrapper>
              <StyledInput
                data-testid="input-message"
                type="text"
                name="message"
                placeholder="Message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              />
            </StyledInputWrapper>

            {errors.message && touched.message && (
              <InputFeedback data-testid="error-message" error="message" />
            )}

            {renderSubmit(isSubmitting)}

            {isSubmitting && (
              <StyledSuccessSnackbar>Message sent</StyledSuccessSnackbar>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

Form.propTypes = {
  recipient: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object,
};

Form.defaultProps = {
  history: null,
};

export default Form;
