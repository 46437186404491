import React from 'react';
import PropTypes from 'prop-types';

import { SocialMedia } from '..';

import StyledFooter from './Footer.style';

const footerDescription = {
  EN: `Built with react, styled components, formik. Hosted on Firebase.`,
  FR: `Réalisé avec react, styled components, formik. Hébergé sur Firebase.`,
};

export const Footer = ({ about: { socialMedia, cv } }) => (
  <StyledFooter>
    <div className="container">
      <SocialMedia socialMedia={socialMedia} cv={cv} />
      <span>{footerDescription.EN}</span>
    </div>
  </StyledFooter>
);

Footer.propTypes = {
  about: PropTypes.shape({
    socialMedia: PropTypes.shape({
      github: PropTypes.string.isRequired,
      instagram: PropTypes.string.isRequired,
      linkedIn: PropTypes.string.isRequired,
      medium: PropTypes.string.isRequired,
      twitter: PropTypes.string.isRequired,
    }),
  }).isRequired,
  cv: PropTypes.shape({
    fr: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
  }),
};

Footer.defaultProps = {
  cv: null,
};

export default Footer;
