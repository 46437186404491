import styled from 'styled-components';

const StyledCard = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 100%;
  margin-bottom: 32px;
  cursor: pointer;
  background-color: transparent;
  transition: 0.3s;

  a:hover {
    opacity: 0.7;
    /* transform: scale(1.1);
    z-index: 1;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.32);
    border: 0; */
  }

  @media only screen and (min-width: 768px) {
    flex: 0 1 48%;
  }
`;

export default StyledCard;
