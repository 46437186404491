export const pasbMarkdown = `
[Pacific Ace Savings Bank (PASB)](https://pacific-ace.com/) is a community bank in Olongapo, Philippines dedicated to serving the financially excluded. 
By providing access to mainstream banking services such as payments, savings, and credit, Pacific Ace Savings Banks aims to lift up underprivileged community members from below the poverty line and break the cycle of destitution.

Typically, PASB clients would go in person to the local office or ATM to perform all their banking transactions. In view of the COVID health crisis, it became necessary to release a functional, user-friendly application that would allow customers to view and manage their accounts remotely.

I built a hybrid mobile application from a single codebase using React Native which can run natively on both Android and iOS.

As the sole front-end developer, my tasks were as follows:

- Collaborate with a back-end developer serving RESTful apis (postman)
- Collaborate with an UI/UX designer (Adobe XD)
- Develop the architecture, navigation, screens and components when keeping in mind scalability`;

export default {
  title: 'Pacific Ace Mobile',
  duration: '8 months',
  subtitle: 'IOS and Android mobile application',
  overview: pasbMarkdown,
  features: [
    'Register a new user',
    'Login page',
    'Display bank accounts',
    'Display checking and saving accounts transactions',
    'Display your user profile and upload a photo',
    'Change your password',
    'Forgot your password',
  ],
  technologies: [
    'javascript',
    'typescript',
    'react native',
    '@testing-library/react',
    'expo',
    'axios',
    'git',
    'github actions',
    'firebase',
  ],
  pictures: [
    'pacific-ace-mobile/pacific-ace-mobile.png',
    'pacific-ace-mobile/pacific-ace-mobile2.png',
  ],
  url: 'https://play.google.com/store/apps/details?id=com.pacific.ace.pasb&hl=en_US&gl=US',
  code: '',
};
