import React from 'react';
import PropTypes from 'prop-types';

import StyledButton from './Button.style';

export const Button = ({
  name,
  filterTag,
  btnType,
  filterSelect,
  resetFilters,
}) => {
  const handleClick = () => {
    if (btnType !== 'tag') return null;
    if (name !== 'show all') return filterSelect(name);
    return resetFilters();
  };

  return (
    <StyledButton
      data-testid="button"
      type="button"
      onClick={() => handleClick()}
      active={name === filterTag}
    >
      {name}
    </StyledButton>
  );
};

Button.propTypes = {
  name: PropTypes.string,
  filterTag: PropTypes.string,
  btnType: PropTypes.string,
  filterSelect: PropTypes.func,
  resetFilters: PropTypes.func,
};

Button.defaultProps = {
  name: '',
  filterTag: '',
  btnType: '',
  filterSelect: null,
  resetFilters: null,
};

export default Button;
