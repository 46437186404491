import React from 'react';
import PropTypes from 'prop-types';

import { ImageContainer } from '../ImageContainer';

import StyledCard from './Card.style';

export const Card = ({ title, pictures }) => {
  if (pictures.length === 0) return null;

  return (
    <StyledCard>
      <ImageContainer
        src={`${process.env.PUBLIC_URL}/assets/img/${pictures}`}
        // thumb={`${process.env.PUBLIC_URL}/assets/img/airbnb.webp`}
        height={768}
        width={1366}
        alt={title}
        url={`/portfolio/${title}`}
      />
    </StyledCard>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  pictures: PropTypes.string,
};

Card.defaultProps = {
  title: '',
  pictures: '',
};

export default Card;
