import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledMenu = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--main-background-color);
  color: var(--primary-color);
  font-size: 64px;
  z-index: 3;

  .menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    height: 15%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.02);
    transition: 0.3s;
    color: var(--primary-color);
  }

  .menu-item:hover {
    color: var(--secondary-color);
    background-color: var(--primary-color);
  }
`;

export const StyledMenuBtn = styled.button`
  display: flex;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  svg {
    fill: var(--primary-color);
    width: 30px;
    height: 30px;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const StyledMenuLinks = styled.nav`
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

export const StyledMenuLink = styled(NavLink)`
  color: var(--tertiary-color);
  font-size: 18px;
  transition: 250ms linear;

  &:not(:last-child) {
    margin-right: 18px;
  }

  &.active {
    color: var(--primary-color);
  }

  &:hover {
    color: var(--primary-color);
  }
`;

export const StyledWrapper = styled.div`
  padding-top: 24;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: ${({ isToggle }) => (isToggle ? 'sticky' : 'initial')};
  top: ${({ isToggle }) => (isToggle ? '0' : 'initial')};
`;
