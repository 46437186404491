export const airbnbMarkdown = `
To deepen my understanding of React Native, I chose to build a clone of the airbnb mobile app.`;

export default {
  title: 'airbnb',
  duration: '3 days',
  subtitle: 'An airbnb clone IOS and Android mobile application',
  overview: airbnbMarkdown,
  features: [
    'Display all ads as a list',
    'Display all ads on a map',
    'Display single ad',
    'Images carousel',
    'Chat',
    'Create a user account',
    'Login to your user account',
  ],
  technologies: ['javascript', 'react native', 'axios', 'git'],
  pictures2: {
    cover: {
      width: 1366,
      height: 768,
      alt_description: null,
      path: {
        regular: 'airbnb_cover.png',
        small: '',
        thumb: '',
      },
    },
    album: [
      {
        width: 1366,
        height: 768,
        alt_description: null,
        path: {
          regular: 'airbnb_cover.png',
          small: '',
          thumb: '',
        },
      },
    ],
  },
  pictures: ['airbnb.png'],
  // url:
  //   'https://expo.io/appetize-simulator?url=https://expo.io/@alexdisdier/AirbnbApp',
  url: '',
  code: 'https://github.com/alexdisdier/airbnb-app',
};
