export const kidiwiMarkdown = `
Kid’Iwi will help you find activities around you, join them on the spot or save them for later. 

These activities will be offered either by parents or by professional artists. You can find activities according to your children tastes, either for free or paid, simple or elaborate, sporty, creative, cultural, artistic and more. Kid’Iwi needed an application to implement its mission. 

A team of five developers developed a full stack react native IOS and Android app MVP.`;

export default {
  title: "kid'iwi",
  duration: '3 months',
  subtitle: 'IOS and Android mobile application',
  overview: kidiwiMarkdown,
  features: [
    'Display all events',
    'Display all events on a map',
    'Display single event',
    'Images carousel',
    'Search filters',
    'Subscribe to an event',
    'Stripe payment',
    'Create an user account',
    'Login to your user account',
    'Create and login with Facebook',
    'Create an event',
    'Display all your past, future and favorite events',
    'Display, modify or delete your user profile',
  ],
  technologies: [
    'javascript',
    'react native',
    'expo',
    'axios',
    'cloudinary',
    'nodejs',
    'express',
    'express router',
    'mongodb',
    'mongoose',
    'heroku',
    'mLab',
    'git',
  ],
  pictures: ['kidiwi/kidiwi.png'],
  url: '',
  // url:
  //   'https://expo.io/appetize-simulator?url=https://expo.io/@alexdisdier/kidiwi-stack',
  code: '',
};
