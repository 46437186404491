import React from 'react';
import PropTypes from 'prop-types';
import Animate from 'react-smooth';
import { Header, Form } from '../../components';

import Helmet from '../../components/Helmet';

import StyledPageHeader from '../../components/Header/Header.style';
import { StyledContactRoot } from './Contact.style';

export const Contact = ({
  lang,
  toggleLang,
  about: { email, contactText },
  ...restOfProps
}) => (
  <div className="page">
    <Helmet
      lang={lang}
      titleEn="Contact"
      contentEn={contactText}
      canonicalUrl="https://alexdisdier.fr/contact"
    />
    <div className="container">
      <StyledPageHeader>
        <Animate to="1" from="0" attributeName="opacity">
          <div className="page-header-title">
            <h2>contact</h2>
          </div>
        </Animate>
        <Header toggleLang={toggleLang} />
      </StyledPageHeader>
      <StyledContactRoot>
        <p>{contactText}</p>
        <Form lang={lang} recipient={email} {...restOfProps} />
      </StyledContactRoot>
    </div>
  </div>
);

Contact.propTypes = {
  about: PropTypes.shape({
    email: PropTypes.string,
    contactText: PropTypes.string,
  }).isRequired,
  toggleLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Contact;
