export const shortUrlMarkdown = `
To deepen my understanding of react, I decided to create a short url generator Open Source. 

I also developed the API and stored my data on MongoDB.`;

export default {
  title: 'short url',
  duration: '3 days',
  subtitle: 'A Full-Stack web application',
  overview: shortUrlMarkdown,
  features: ['Shorten a url', 'Copy a url', 'Click on the shorten url'],
  technologies: [
    'html5',
    'css',
    'javascript',
    'typescript',
    'react',
    'axios',
    'nodejs',
    'express',
    'mongodb',
    'mongoose',
    'heroku',
    'mLab',
    'git',
    'aws',
    'pwa',
  ],
  pictures: ['short-url.png'],
  url: 'https://www.ptitlink.live',
  code: 'https://github.com/alexdisdier/short-url',
};
