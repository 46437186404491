import React from 'react';

import './Features.css';

const Features = ({ features }) => {
  return (
    <>
      <h3>Key Features</h3>
      <ul>
        {features.map((feature) => {
          return <li key={feature}>{feature}</li>;
        })}
      </ul>
    </>
  );
};

export default Features;
