import styled from 'styled-components';

export const StyledAboutRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;

  img {
    max-width: 100%;
  }

  h3 {
    width: 100%;
    margin-bottom: 24px;
    color: var(--primary-color);
    font-size: 32px;
    border-bottom: 0.5px solid rgba(229, 229, 229, 0.5);
    text-align: center;
  }

  p {
    margin-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    text-align: left;
  }

  a {
    color: var(--tertiary-color);
    border-bottom: 1px solid var(--primary-color);
    transition: 250ms linear;
    opacity: 0.7;
  }

  a:hover {
    opacity: 1;
  }
`;
