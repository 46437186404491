import React from 'react';

import './TechnicalSheet.css';

const TechnicalSheet = ({ technologies }) => {
  return (
    <>
      <h3>Technical Sheet</h3>
      <ul>
        {technologies.map((technology) => {
          return <li key={technology}>{technology}</li>;
        })}
      </ul>
    </>
  );
};

export default TechnicalSheet;
