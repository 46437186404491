import React from 'react';

import useAnalyticsEventTracker from '../../../hooks/useAnalyticsEventTracker';
import { Button } from '../../../components';

import './ActionButtons.css';

const ActionButtons = ({ url, code }) => {
  const gaEventTracker = useAnalyticsEventTracker('Portfolio');

  return (
    <div className="btn-view-container">
      {url && (
        <a
          className="btn-view"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => gaEventTracker('Clicked on live demo', url)}
        >
          <Button name="Live Demo" />
        </a>
      )}
      {code && (
        <a
          className="btn-view"
          href={code}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => gaEventTracker('Clicked on source code demo', code)}
        >
          <Button name="Source Code" />
        </a>
      )}
    </div>
  );
};

export default ActionButtons;
