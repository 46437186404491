import styled from 'styled-components';

const StyledButton = styled.button`
  background: ${({ active }) =>
    active ? 'var(--primary-color)' : 'transparent'};
  color: ${({ active }) =>
    active ? 'var(--secondary-color)' : 'var(--primary-color)'};
  border: 1px solid var(--primary-color);
  padding: 8px 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: 250ms linear;
  font-size: 16px;
  font-family: inherit;

  &:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }

  &:focus {
    outline: none;
  }
`;

export default StyledButton;
