import React from 'react';
import Helmet from 'react-helmet';

const helmet = ({
  lang,
  titleEn,
  titleFr,
  contentEn,
  contentFr,
  canonicalUrl,
}) => {
  return (
    <Helmet>
      <html lang={lang} />
      <title>{lang === 'en' ? titleEn : titleFr}</title>
      <meta
        property="og:title"
        content={lang === 'en' ? contentEn : contentFr}
      />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default helmet;
