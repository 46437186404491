export const todoMarkdown = `
A todo list is a great exercise to practice new technologies. 
When I first created this side project, I had also developed the API and stored data on MongoDB. 

Since there is no authentification in place, I changed the logic and I now save data in the [localStorage](https://developers.google.com/web/tools/chrome-devtools/storage/localstorage) of your browser.`;

export default {
  title: 'todo',
  duration: '3 days',
  subtitle: 'A Full-Stack web application',
  overview: todoMarkdown,
  features: [
    'Add a task',
    'Edit',
    'Check and cross out',
    'Delete',
    'Drag and drop',
    'Mobile responsive with swiping gestures for deletion',
  ],
  technologies: [
    'html5',
    'css',
    'javascript',
    'typescript',
    'react',
    'react-beautiful-dnd',
    'git',
    // 'pwa',
  ],
  pictures: [
    'todo/todo_browser.png',
    'todo/todo_desktop.jpg',
    'todo/todo_responsive.png',
  ],
  url: 'https://alexdisdier.github.io/todo-client/',
  code: 'https://github.com/alexdisdier/todo-client',
};
