import styled from 'styled-components';

export const StyledHomeRoot = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 30px;
`;

export const StyledGalleryContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
`;

export default {
  StyledHomeRoot,
  StyledGalleryContainer,
};
