import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledPageHeader from './Header.style';

export const Header = ({ home }) => {
  const link = (
    <a href="https://www.thetrainline.com/" target="_blank" rel="noreferrer">
      Trainline
    </a>
  );

  if (!home) return null;

  return (
    <StyledPageHeader>
      <div>
        <h2>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Hi, my name is Alex Disdier. I'm a Software Engineer at {link}, based
          in Paris.
          <br />
          Here’s my portfolio of side projects.
        </h2>
      </div>
    </StyledPageHeader>
  );
};

Header.propTypes = {
  home: PropTypes.bool,
};

Header.defaultProps = {
  home: false,
};

export default memo(Header);
